import PropTypes from "prop-types"
import { Box } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
	Button,
	ButtonGroup,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	InputBase,
	MenuItem,
	Select,
	Stack,
	Switch,
	ToggleButton,
	ToggleButtonGroup,
	InputLabel,
	IconButton,
	Alert,
	Link,
} from "@mui/material";
import { FormikField } from "src/molecules";
import { ToggleButtons } from "src/atoms";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import { useAuth } from "src/utils/auth";
import jwt_decode from "jwt-decode";
import { convertFromRaw } from "draft-js";
import { ArrowForwardIosRounded } from "@mui/icons-material";
import { route } from "src/Routes";

const USER_INFO = gql`
	query user($user_id: Int!) {
		userById(user_id: $user_id) {
			user_id
			first_name
			last_name
			email
			street
			city
			zip_code
		}
	}
`;

const COURSE_SEASONS = gql`
	query seasonsbyCourseId($course_id: Int!) {
		ballroomSeasonsByCourseId(course_id: $course_id) {
			season
			season_id
			capacity
			start_date
			end_date
			count
		}
	}
`;

const BUY_TICKETS = gql`
	mutation buyTickets(
		$user_id: Int!
		$course_id: Int!
		$count: Int!
		$price: Int!
		$payment: String!
		$ticket_type: String!
		$season_id: Int!
	) {
		buyTickets(
			user_id: $user_id
			course_id: $course_id
			count: $count
			price: $price
			payment: $payment
			ticket_type: $ticket_type
			season_id: $season_id
		)
	}
`;

const PRICES = gql`
	query coursePrices($course_id: Int!) {
		coursePrices(course_id: $course_id) {
			price_id
			course_id
			single
			single_student
			ten
			ten_student
			twenty
			twenty_student
		}
	}
`;

const USERS_ORDERS = gql`
	query usersTicketOrders($user_id: Int!) {
		usersTicketOrders(user_id: $user_id) {
			season_id
		}
	}
`;

const schema = yup.object().shape({
	first_name: yup.string().required("Povinné pole").label("Jméno"),
	last_name: yup.string().required("Povinné pole").label("Příjmení"),
	payment: yup.string().required("Vyberte způsob platby").label("Platba"),
	street: yup.string().required("Povinné pole").label("Ulice"),
	season: yup.string().required("Vyberte lekci").label("Lekce"),
	city: yup.string().required("Povinné pole").label("Město"),
	zipCode: yup.string().required("Povinné pole").label("PSČ"),
	email: yup
		.string()
		.email("Nevalidní e-mail")
		.required("Povinné pole")
		.label("E-mail"),
});

/**
 * Dialog to buy entries for certain season in certain course.
 * @param {Boolean} open opened/closed dialog
 * @param {Function} setOpen open/close dialog
 * @param {Number} courseName course name
 * @param {Number} courseId course id
 * @param {Function} setSnackState function to set snack state Success, Warning, Error, Info
 * @param {Function} setSnackMsg function to set snack msg
 * @param {Function} setSnackOpen function to open/close informational snack message
 * @param {Function} setOpenSuccess Opens dialog with successful message
 * @param {Function} setFinalPrice Sets the final price in the dialog
 * @returns {Component} Dialog
 */
export function BuyCourseDialog({
	setOpen,
	open,
	courseName,
	courseId,
	setSnackState,
	setSnackMsg,
	setSnackOpen,
	setOpenSuccess,
	setFinalPrice,
}) {
	const { token } = useAuth();
	const decoded = token && jwt_decode(token);
	const [user_id] = useState(decoded?.user_id);
	const [userInSeasons, setUserInSeasons] = useState([]);

	const { data, loading } = useQuery(USER_INFO, {
		variables: {
			user_id: parseInt(user_id),
		},
		skip: !token,
	});

	const pricesQuery = useQuery(PRICES, {
		variables: {
			course_id: parseInt(courseId),
		},
	});

	const seasonsQuery = useQuery(COURSE_SEASONS, {
		variables: {
			course_id: parseInt(courseId),
		},
	});

	const userInSeasonsQuery = useQuery(USERS_ORDERS, {
		variables: {
			user_id: parseInt(user_id),
		},
		skip: !token,
	});

	const [first_name, setFirstName] = useState("");
	const [last_name, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [payment] = useState("");
	const [street, setStreet] = useState("");
	const [city, setCity] = useState("");
	const [zipCode, setZipCode] = useState("");
	const [counter, setCounter] = useState(0);
	const [price, setPrice] = useState(0);
	const [student, setStudent] = useState(false);
	const [paymentBundle, setPaymentBundle] = useState("");
	const [season, setSeason] = useState("");

	useEffect(() => {
		if (!loading && data) {
			setFirstName(data.userById.first_name);
			setLastName(data.userById.last_name);
			setEmail(data.userById.email);
			setStreet(data.userById.street);
			setCity(data.userById.city);
			setZipCode(data.userById.zip_code);
		}
	}, [loading, data]);

	const initialValues = {
		first_name: first_name,
		last_name: last_name,
		payment: payment,
		email: email,
		city: city,
		street: street,
		zipCode: zipCode,
		season: "",
	};

	const handleClose = useCallback(() => {
		setCounter(0);
		setPrice(0);
		setPaymentBundle("");
		setSeason("");
		setOpen(false);
	}, [setOpen]);

	const handleIncrement = () => {
		if (counter < 20) {
			setCounter(counter + 1);
		}
	};

	const handleDecrement = () => {
		if (counter > 1) {
			setCounter(counter - 1);
		}
	};

	const [buyTicketsRequest] = useMutation(BUY_TICKETS, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.buyTickets);
			setSnackOpen(true);
			handleClose();
			setOpenSuccess(true);
			setFinalPrice(price);
		},
		onError: (error) => {
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const handleBookTickets = useCallback(
		(values) => {
			buyTicketsRequest({
				variables: {
					user_id: user_id,
					course_id: courseId,
					count: counter,
					price: price,
					payment: values.payment,
					ticket_type: paymentBundle + (student ? "_student" : ""),
					season_id: parseInt(season),
				},
			});
		},
		[
			buyTicketsRequest,
			counter,
			courseId,
			paymentBundle,
			price,
			season,
			student,
			user_id,
		]
	);

	useEffect(() => {
		if (paymentBundle === "ten") {
			setCounter(10);
		} else if (paymentBundle === "twenty") {
			setCounter(20);
		} else {
			return setPrice(
				pricesQuery.data &&
				0 +
				counter *
				pricesQuery.data.coursePrices[
				paymentBundle + (student ? "_student" : "")
				]
			);
		}
		return setPrice(
			pricesQuery.data &&
			pricesQuery.data.coursePrices[
			paymentBundle + (student ? "_student" : "")
			]
		);
	}, [counter, paymentBundle, pricesQuery.data, student]);

	useEffect(() => {
		if (userInSeasonsQuery.data) {
			let seasons = [];
			userInSeasonsQuery.data.usersTicketOrders.forEach((order) => {
				if (!seasons.includes(order.season_id)) {
					seasons.push(order.season_id)
				}
			});
			setUserInSeasons(seasons);
		}
	}, [userInSeasonsQuery.data]);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth="md"
			fullWidth={true}
		>
			<Formik
				initialValues={initialValues}
				validationSchema={schema}
				validateOnBlur={false}
				enableReinitialize={true}
				onSubmit={handleBookTickets}
			>
				{({ setFieldValue }) => (
					<Form
						style={{
							height: "inherit",
							justifyContent: "space-evenly",
							display: "flex",
							flexDirection: "column",
							width: "100%",
						}}
					>
						<DialogTitle
							id="alert-dialog-title"
							sx={{ whiteSpace: "pre-line", alignSelf: "center" }}
						>
							{convertFromRaw(
								JSON.parse(courseName)
							).getPlainText()}
						</DialogTitle>
						<DialogContent sx={{ width: "100%", py: "0px" }}>
							<Typography
								variant="body1"
								color="initial"
								textAlign="center"
								sx={{
									m: "0px",
									p: "0px",
									display:
										paymentBundle !== "" ? "none" : "block",
								}}
							>
								Zvolte balíček hodin
							</Typography>
							<ToggleButtonGroup
								exclusive
								fullWidth={true}
								size="small"
								value={paymentBundle}
								onChange={(event, newValue) => {
									newValue !== null &&
										setPaymentBundle(newValue);
									setCounter(1);
								}}
								sx={{ mb: "20px" }}
							>
								<ToggleButton
									sx={{
										borderRadius: "5px !important",
										mr: "5px !important",
										px: "5px",
									}}
									size="small"
									value="single"
								>
									Jednorázový vstup
								</ToggleButton>
								<ToggleButton
									sx={{
										borderRadius: "5px !important",
										mx: "5px !important",
										px: "5px",
									}}
									value="ten"
								>
									Balíček 10 lekcí
								</ToggleButton>
								{pricesQuery.data &&
									pricesQuery.data.coursePrices.twenty !==
									null && (
										<ToggleButton
											sx={{
												borderRadius: "5px !important",
												ml: "5px !important",
												px: "5px",
											}}
											value="twenty"
										>
											Balíček 20 lekcí
										</ToggleButton>
									)}
							</ToggleButtonGroup>
							<Box
								sx={{
									visibility:
										paymentBundle === ""
											? "hidden"
											: "visible",
								}}
							>
								<ButtonGroup
									sx={{
										justifyContent: "flex-start",
										alignItems: "flex-start",
										width: "100%",
										mb: "20px",
										display:
											paymentBundle === "single"
												? "flex"
												: "none",
									}}
								>
									<Button
										variant="contained"
										onClick={handleDecrement}
										sx={{
											width: "5ch",
											height: "40px",
										}}
									>
										<Typography
											variant="body1"
											fontWeight="900"
											color="initial"
										>
											-
										</Typography>
									</Button>
									<InputBase
										id="counter"
										name="counter"
										sx={{
											fontWeight: "bold",
											textAlignLast: "center",
											color: "black",
											width: "40px",
											height: "40px",
											mx: "2px",
											display: "flex",
											alignItems: "center",
										}}
										value={counter}
									></InputBase>
									<Button
										variant="contained"
										onClick={handleIncrement}
										sx={{
											width: "5ch",
											height: "40px",
										}}
									>
										<Typography
											variant="body1"
											fontWeight="900"
											color="initial"
										>
											+
										</Typography>
									</Button>
								</ButtonGroup>
								<FormControl
									sx={{
										mb: "20px",
										display:
											pricesQuery.data &&
												pricesQuery.data.coursePrices[
												paymentBundle + "_student"
												] !== null &&
												pricesQuery.data.coursePrices[
												paymentBundle + "_student"
												] !== undefined
												? "block"
												: "none",
									}}
								>
									<FormControlLabel
										control={
											<Switch
												checked={student}
												onChange={() => {
													setStudent(!student);
												}}
											/>
										}
										label="Student"
									/>
								</FormControl>
								<Stack
									direction="row"
									spacing={2}
									justifyContent="center"
									width="100%"
								>
									<Stack
										direction="column"
										spacing={2}
										justifyContent="center"
										width="100%"
										height="fit-content"
									>
										<FormikField
											id="first_name"
											name="first_name"
											label="Jméno"
											type="text"
											variant="outlined"
											as="TextField"
											color="secondary"
											disabled
										/>
										<FormikField
											id="last_name"
											name="last_name"
											label="Příjmení"
											type="text"
											variant="outlined"
											as="TextField"
											color="secondary"
											disabled
										/>
										<FormikField
											id="email"
											name="email"
											label="E-mail"
											type="email"
											variant="outlined"
											as="TextField"
											color="secondary"
											disabled
										/>
										<ToggleButtons
											id="payment"
											name="payment"
											label="Platba"
											type="text"
											variant="outlined"
										/>
									</Stack>
									<Stack
										direction="column"
										spacing={2}
										justifyContent="flex-start"
										width="100%"
									>
										<FormikField
											id="street"
											name="street"
											label="Ulice"
											type="text"
											variant="outlined"
											as="TextField"
											color="secondary"
											disabled
										/>
										<FormikField
											id="city"
											name="city"
											label="Město"
											type="text"
											variant="outlined"
											as="TextField"
											color="secondary"
											disabled
										/>
										<FormikField
											id="zipCode"
											name="zipCode"
											label="PSČ"
											type="text"
											variant="outlined"
											as="TextField"
											color="secondary"
											disabled
										/>
										<FormControl color="secondary">
											<InputLabel>Lekce</InputLabel>
											<Select
												value={season}
												onChange={(event) => {
													setSeason(
														event.target.value
													);
													setFieldValue(
														"season",
														event.target.value
													);
												}}
												label="season"
												name="season"
												id="season"
											>
												<MenuItem value={""}>
													<em>Žádná</em>
												</MenuItem>
												{seasonsQuery.data &&
													seasonsQuery.data.ballroomSeasonsByCourseId.map(
														(season) => (
															<MenuItem
																key={
																	season.season_id
																}
																value={
																	season.season_id
																}
																// disabled={
																// 	season.count >=
																// 	season.capacity && (!userInSeasons.includes(season.season_id))
																// }
																disabled={
																	new Date(season.end_date.split(". ").reverse().join("/")) < new Date()
																}
															>
																{season.season}
																{/* {season.count >=
																	season.capacity && (!userInSeasons.includes(season.season_id))
																	? " - Lekce zaplněna"
																	: ""} */}
																{
																	new Date(season.end_date.split(". ").reverse().join("/")) < new Date()
																	? " - Lekce skončila"
																	: ""
																}
															</MenuItem>
														)
													)}
											</Select>
											<ErrorMessage
												name="season"
												component="div"
												className="invalid-feedback"
											/>
										</FormControl>
										<Typography
											variant="body1"
											fontSize="1.4em"
											color="initial"
											sx={{
												mt: "auto !important",
												display: "flex",
												ml: "auto",
												fontWeight: "bold",
											}}
										>
											Cena: {price} Kč
										</Typography>
									</Stack>
								</Stack>
							</Box>
						</DialogContent>
						<DialogActions>
							{!token && (
								<Button
									className="scale3"
									sx={{
										display: "flex",
										textTransform: "none !important",
										mt: "auto",
										mr: "auto",
										width: "100%",
									}}
									component={Link}
									href={route.signIn()}
								>
									<Alert
										sx={{
											pl: "20px",
											width: "100%",
											display: "flex",
											flexWrap: "nowrap",
										}}
										variant="filled"
										severity="info"
										action={
											<IconButton
												aria-label="close"
												color="inherit"
												size="small"
											>
												<ArrowForwardIosRounded fontSize="inherit" />
											</IconButton>
										}
									>
										Pro rezervaci termínu musíte být
										přihlášen(a)
									</Alert>
								</Button>
							)}
							<Button
								type="submit"
								variant="contained"
								color="secondary"
								disabled={
									counter === 0 ? true : false || !token
								}
							>
								Objednat
							</Button>
							<Button onClick={handleClose} color="secondary">
								Zrušit
							</Button>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
}

BuyCourseDialog.propTypes = {
	courseId: PropTypes.any,
	courseName: PropTypes.any,
	open: PropTypes.any,
	setFinalPrice: PropTypes.func,
	setOpen: PropTypes.func,
	setOpenSuccess: PropTypes.func,
	setSnackMsg: PropTypes.func,
	setSnackOpen: PropTypes.func,
	setSnackState: PropTypes.func
}
